.bgp-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.bgp-form-class {
    padding-top: 30px;
	width: 300px;
}

.bgp-holder {
    color: #BE5108;
}
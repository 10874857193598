.whois-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.whois-form-class {
    padding-top: 30px;
	width: 300px;
}

.whois-results {
    font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
	color: #BE5108;
	font-weight: bold;
}


.masonry-container {
	max-width: 1500px;
}

.panel {
	margin: 10px;
      width: 300px;
      word-wrap: break-word;
}

.panel > .panel-heading {
	color: #BE5108;
    background-image: none;
    background-color: #fff;
    border-color: #ddd;
}

.panel-title {
    font-size: 14px;
}
.bitsnbytes-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.bitsnbytes-form-class {
    width: 500px;
    padding-top: 30px;
}

.bitnsbytes-form-select {
    height: 34px;
    margin-left: 2px;
    border-radius: 3px;
    color: #646464;
}

.bitsnbytes-field-class {
    padding-top: 30px;
    white-space: nowrap;
}

.bitsnbytes-field-class-hidden {
	padding-top: 30px;
	display: none;
}

.bitsnbytes-field-title {
	display: inline-block;
	width: 200px;
	padding-bottom: 30px;
    font-size: 18px;
}

.bitsnbytes-field-value {
	display: inline-block;
	font-size: 18px;
    color: #BE5108;
}
.image {
    padding-left: 70px;
}

.linkFade {
    transition: color .5s ease-out;
}

.linkFade:hover {
  color: #BE5108;
  cursor: pointer;
}

.github-footer {
	position: absolute;
	padding-left: 70px;
    bottom: 20px;
}
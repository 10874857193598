.container {
  
  background-color: darkslategray;
}

drawer {
    z-index: 2;
    position: absolute;
    top: 0px;
    bottom: 0px;
    transition: -webkit-transform 0.3s ease-out;
    will-change: transform;
    background-color: white;
    overflow-y: auto;
    left: 0px;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    box-shadow: rgba(0, 0, 0, 0.14902) 2px 2px 4px;
}

.sidebarClass {
    z-index: 4 !important;
}

.overlayClass {
    z-index: 3 !important;
}
.image {
    padding-left: 70px;
}

.linkFade {
    -webkit-transition: color .5s ease-out;
    transition: color .5s ease-out;
}

.linkFade:hover {
  color: #BE5108;
  cursor: pointer;
}

.github-footer {
	position: absolute;
	padding-left: 70px;
    bottom: 20px;
}
.notfound-root-div{
    padding-left: 30px;
    padding-top: 15px;
	color: darkgray;
}
.subnetting-root-div{
    padding: 15px 30px;
}

.subnetting-form-class {
    padding-top: 14px;
}

.subnetting-slash {
    font-size: 32px;
    padding: 0px 5px;
    margin-top: -5px;
}

.subnetting-field-class {
    padding-top: 30px;
    white-space: nowrap;
}

.subnetting-field-class-hidden {
	padding-top: 30px;
	display: none;
}

.subnetting-field-title {
	display: inline-block;
	width: 180px;
	padding-bottom: 30px;
	font-size: 18px;

}

.subnetting-field-value {
	display: inline-block;
	font-size: 18px;
    color: #BE5108;
   
}

.subnet-inline {
	display: flex;
}
.dns-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.dns-form-class {
    padding-top: 30px;
	width: 300px;
}

.record-class{
    padding-top: 10px;
}

.record-class-hidden{
    display: none;
}
.dnsrecord-masonry-container {
	max-width: 1500px;
}

.dnsrecord-panel {
	margin: 10px;
      width: 300px;
      word-wrap: break-word;
}

.mac-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.mac-form-class {
    padding-top: 30px;
	width: 300px;
}

.mac-field-class {
    padding-top: 30px;
    white-space: nowrap;
}

.mac-field-class-hidden {
	padding-top: 30px;
	display: none;
}

.mac-field-title {
	display: inline-block;
	width: 180px;
	padding-bottom: 30px;
	font-size: 18px;

}

.mac-field-value {
	display: inline-block;
	font-size: 18px;
    color: #BE5108;
   
}
.whois-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.whois-form-class {
    padding-top: 30px;
	width: 300px;
}

.whois-results {
    font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
	color: #BE5108;
	font-weight: bold;
}


.converter-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.converter-form-class {
    width: 300px;
	padding-top: 30px;
}

.converter-field-class {
    padding-top: 30px;
    white-space: nowrap;
}

.converter-field-class-hidden {
	padding-top: 30px;
	display: none;
}

.converter-field-title {
	display: inline-block;
	width: 200px;
	padding-bottom: 30px;
	font-size: 18px;

}

.converter-field-value {
	display: inline-block;
	font-size: 18px;
	color: #BE5108;
}
.bgp-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.bgp-form-class {
    padding-top: 30px;
	width: 300px;
}

.bgp-holder {
    color: #BE5108;
}
ul {
  list-style-type: none;
}
.masonry-container {
	max-width: 1500px;
}

.panel {
	margin: 10px;
      width: 300px;
      word-wrap: break-word;
}

.panel > .panel-heading {
	color: #BE5108;
    background-image: none;
    background-color: #fff;
    border-color: #ddd;
}

.panel-title {
    font-size: 14px;
}
.bitsnbytes-root-div{
    padding-left: 30px;
    padding-top: 15px;
}

.bitsnbytes-form-class {
    width: 500px;
    padding-top: 30px;
}

.bitnsbytes-form-select {
    height: 34px;
    margin-left: 2px;
    border-radius: 3px;
    color: #646464;
}

.bitsnbytes-field-class {
    padding-top: 30px;
    white-space: nowrap;
}

.bitsnbytes-field-class-hidden {
	padding-top: 30px;
	display: none;
}

.bitsnbytes-field-title {
	display: inline-block;
	width: 200px;
	padding-bottom: 30px;
    font-size: 18px;
}

.bitsnbytes-field-value {
	display: inline-block;
	font-size: 18px;
    color: #BE5108;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  color: #555555;
}


.container {
  
  background-color: darkslategray;
}

drawer {
    z-index: 2;
    position: absolute;
    top: 0px;
    bottom: 0px;
    transition: -webkit-transform 0.3s ease-out;
    will-change: transform;
    background-color: white;
    overflow-y: auto;
    left: 0px;
    transform: translateX(0%);
    box-shadow: rgba(0, 0, 0, 0.14902) 2px 2px 4px;
}

.sidebarClass {
    z-index: 4 !important;
}

.overlayClass {
    z-index: 3 !important;
}
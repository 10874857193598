.subnetting-root-div{
    padding: 15px 30px;
}

.subnetting-form-class {
    padding-top: 14px;
}

.subnetting-slash {
    font-size: 32px;
    padding: 0px 5px;
    margin-top: -5px;
}

.subnetting-field-class {
    padding-top: 30px;
    white-space: nowrap;
}

.subnetting-field-class-hidden {
	padding-top: 30px;
	display: none;
}

.subnetting-field-title {
	display: inline-block;
	width: 180px;
	padding-bottom: 30px;
	font-size: 18px;

}

.subnetting-field-value {
	display: inline-block;
	font-size: 18px;
    color: #BE5108;
   
}

.subnet-inline {
	display: flex;
}